// eslint-disable-next-line func-names
(function () {
  if (typeof window.requestIdleCallback === 'function') {
    return;
  }

  window.requestIdleCallback = cb =>
    window.setTimeout(() => {
      cb({
        timeRemaining: () => Infinity,
        didTimeout: false
      });
    }, 0);

  window.cancelIdleCallback = ricId => window.clearTimeout(ricId);
})();
