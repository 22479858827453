// eslint-disable-next-line func-names
(function (window) {
  try {
    // eslint-disable-next-line no-new
    new window.CustomEvent('test');
  } catch (e) {
    return; // No need to polyfill
  }

  // Polyfills DOM4 CustomEvent
  function MouseEvent(eventType, params) {
    // eslint-disable-next-line no-param-reassign
    params = params || { bubbles: false, cancelable: false };
    const mouseEvent = document.createEvent('MouseEvent');
    mouseEvent.initMouseEvent(
      eventType,
      params.bubbles,
      params.cancelable,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );

    return mouseEvent;
  }

  MouseEvent.prototype = window.Event.prototype;

  // eslint-disable-next-line no-param-reassign
  window.MouseEvent = MouseEvent;
})(window);
