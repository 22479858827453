/* eslint-disable */

import 'core-js/features/promise';
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/symbol';
import 'core-js/features/weak-map';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/function/bind';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/keys';
import 'core-js/features/array/fill';
import 'core-js/features/array/includes';
import 'core-js/features/array/from';
import 'core-js/features/array/at';
import 'core-js/features/string/includes';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/starts-with';
import 'core-js/features/object/assign';
import 'core-js/features/object/keys';
import 'core-js/features/number/is-integer';
import 'core-js/features/number/is-nan';
import 'classlist.js';
import 'matchmedia-polyfill';
import 'intersection-observer';
import 'whatwg-fetch/fetch';
import '../src/util/polyfills/mouseEvent';
import '../src/util/polyfills/requestIdleCallback';
import smoothscroll from 'smoothscroll-polyfill';
import * as clipboard from 'clipboard-polyfill';

window.__clipboardPolyfill = clipboard;

/* eslint-enable */

smoothscroll.polyfill();
